<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Edit Milestone</h1>
            <hr>
            <div v-if="!isLoad" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="patchMilestone" class="row p-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-lg-12 mt-4">
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <label for="">Year</label>
                                                <input type="number" class="form-control" v-model="form.year" disabled required>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Description</label>
                                                <textarea class="form-control" v-model="form.description" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-right">
                                        <button type="submit" class="btn btn-current">
                                        Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true
export default {
    data(){
        return {
            isLoad: true,
            idMilestone: this.$route.params.idMilestone,
            form : {
                id: '',
                year: '',
                description: '',
            }
        }
    },
    created(){
        this.getMilestone()
    },
    methods: {
        async getMilestone(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/about/milestones/detail/${this.idMilestone}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.form = res.data.data
                    this.isLoad = false
                }
            }).catch((err) => console.error(err.response))
        },
        async patchMilestone() {
            var data = {
                year: this.form.year,
                description: this.form.description,
                _method: 'patch'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/about/milestones/${this.form.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Update Milestone!',
                    text: res.data.data,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SettingsAboutAdmin', hash: '#MILESTONES' })
            }).catch(err => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    this.$swal({
                        toast: true,
                        title: 'Create Milestone!',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>